import React from 'react';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';

export const RequireLogin = ({ children }) => {

    let isLogged = Cookies.get('auth-token');

    if (isLogged) {
        return <Navigate to="/games" />
    }

    return children
}