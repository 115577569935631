import { ActionTypes } from "../constants/actionTypes";

const initialState = {
    allTests: { tests: [] },
};

export const addNewTestReducer = (state = initialState.allTests, { type, payload }) => {
    switch (type) {
        case ActionTypes.ADD_NEW_TEST:
            return { ...state, tests: payload }
        default:
            return state
    }
};