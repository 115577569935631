import { ActionTypes } from "../constants/actionTypes";

const initialState = {
    score: 0,
    level: 1,
};

export const addNewScoreReducer = (state = initialState.score, { type, payload }) => {
    switch (type) {
        case ActionTypes.ADD_NEW_SCORE:
            return payload
        default:
            return state
    }
};

export const addNewLevelReducer = (state = initialState.level, { type, payload }) => {
    switch (type) {
        case ActionTypes.ADD_NEW_LEVEL:
            return payload
        default:
            return state
    }
};