import { combineReducers } from "redux";
import { addNewTestReducer } from "./testReducer";
import { addNewLevelReducer, addNewScoreReducer } from "./gameReducer";

const reducers = combineReducers({
    allTests: addNewTestReducer,
    gameScore: addNewScoreReducer,
    gameLevel: addNewLevelReducer,
});

export default reducers;