import React from 'react';
import '../../styles/utils/SpinnerLoader.scss';

function SpinnerLoader({ full }) {
    return (
        <div className={`SpinnerLoader ${full ? "FullSpinnerLoader" : ""}`}>
            <div className="loader"></div>
        </div>
    )
}

export default SpinnerLoader;